'use strict';

var $ = require('jquery'),
	Shadowbox = require('./Shadowbox'),
	Accordion = require('./Accordion'),
	NewsCategoryFilter = require('./newsCategoryFilter'),
	DropUpComponent = require('./dropUpComponent'),
	GoogleMapsElement = require('./googleMapsElement'),
	SmartLabels = require('./smartLabels'),
	MainNavigation = require('./mainNavigation'),
	ResponsiveNavigation = require('./responsiveNavigation'),
	StickyNavigation = require('./stickyNavigation'),
	CookieAlert = require('./cookieAlert');

// make jQuery available for plugins
global.jQuery = $;
global.$ = $;

$(document).ready(
	function() {
		// enable lightbox
		Shadowbox.bind(
			'.default-lightbox', Shadowbox.types.IMAGE, {
				mainClass: 'default-shadowbox',
				classPrefix: 'default-',
				gallery: {
					enabled: true
				}
			}
		);

		// init accordion
		new Accordion($('.default-accordion-wrap'));

		new DropUpComponent('#search-trigger', '#search', 'drop-up-search');
		new DropUpComponent('#login-trigger', '#login', 'drop-up-login');

		new StickyNavigation('#main-navigation', 768);

		new NewsCategoryFilter();

		new SmartLabels('.smart-label');

		new MainNavigation('#mainMenu');
		new ResponsiveNavigation('#responsive-navigation', '#mainMenu');

		new CookieAlert('#cookie-alert');

		new GoogleMapsElement();

		// enable responsive behaviour for tables
		$('table').wrap('<div class="table-responsive" />');

	}
);

