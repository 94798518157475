module.exports = function(_element) {

	'use strict';

	var mainNavigation = {},
		$ = require('jquery'),
		$element;

	function init() {
		$element = $(_element);
		$element.find('li ul a').each(checkLink);
	}

	function checkLink(index, item) {
		var $item = $(item);
		if (window.location.pathname === $item.attr('href')) {
			$item.parent().addClass('active');
		}
	}

	init();
	return mainNavigation;
};
